// eslint-disable react-hooks/exhaustive-deps

import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import LoadingCircle from '@beelineloans/cx-library/src/components/LoadingCircle';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import GlobalStyle from '@beelineloans/cx-library/src/theme/global-styles';
import { buildPOSURL } from '@beelineloans/cx-library/src/utils/helpers';

const Wrap = styled(Container)`
  height: 100vh;
  .container-inner {
    height: 100vh;

    div {
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const ApplyRedirect = () => {
  useEffect(() => {
    setTimeout(() => {
      if (document) document.location = buildPOSURL(CONST.LINKS.EXTERNAL.POS.APPLY);
    }, 2500);
  }, []);

  return (
    <Wrap>
      <LoadingCircle />
    </Wrap>
  );
};

ApplyRedirect.propTypes = {};

const Apply = () => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    <ApplyRedirect />
  </ThemeProvider>
);

export default Apply;
